export function attachPopover() {
  // Enable UQAM header popover.
  $(".uqam_plus_button").popover({
    html: true,
    container: ".uqam-header",
    offset: "0, 25",
    content: function () {
      return $("#uqam_plus_interieur").html();
    },
  });

  // Based on https://gabarit-adaptatif.uqam.ca/2016/js/gabarit_wp1.js, but
  // adapted for Bootstrap Popover.
  $(".uqam_plus_button").click(function (e) {
    if ($(".uqam_plus_button").hasClass("active")) {
      fermerPlusUqam();
    } else {
      ouvrirPlusUqam();
    }
    e.stopPropagation();
  });
  $("#uqam_plus_interieur").click(function (e) {
    e.stopPropagation();
  });
  $(document).on("click", function (event) {
    if ($(".uqam_plus_button").hasClass("active")) {
      fermerPlusUqam();
    }
  });
  function ouvrirPlusUqam() {
    $(".uqam_plus_button").addClass("active").popover("show");
  }
  function fermerPlusUqam() {
    $(".uqam_plus_button").removeClass("active").popover("hide");
  }
}
