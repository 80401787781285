import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import Popper from "popper.js";
window.Popper = Popper;

//
// Bootstrap components -- Unneeded components are commented-out.
//

import "bootstrap/js/dist/alert.js";
import "bootstrap/js/dist/button.js";
// import "bootstrap/js/dist/carousel.js";
import "bootstrap/js/dist/collapse.js";
import "bootstrap/js/dist/dropdown.js";
import "bootstrap/js/dist/modal.js";
import "bootstrap/js/dist/popover.js"; // Required by UQAM header.
// import "bootstrap/js/dist/scrollspy.js";
import "bootstrap/js/dist/tab.js";
// import "bootstrap/js/dist/toast.js";
// import "bootstrap/js/dist/tooltip.js";
import "bootstrap/js/dist/util.js";

import { attachPopover } from "./uqam.js";

$(document).ready(attachPopover);
